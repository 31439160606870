import React, {createContext, useContext, useEffect, useState} from 'react'
import {v4 as uuid} from 'uuid'
import {
  CreateUserMessage,
  GetBreakdownPrompt,
  GetBriefSummaryPrompt, GetDetailedSummaryPrompt,
  GetInitialPrompt,
  MakeChatGPTApiRequest,
  Message
} from '../core/GPT'
import {LoadingOverlay} from '@mantine/core'
import OpenAI from 'openai'

export const AppContext = createContext({} as IContextProps)

type ListItem = {
  text: string
  children?: ListItem[]
}

const findSection = (sections: Array<Section>, id: string): Section | null => {
  for (const s of sections) {
    if (s.id === id) {
      return s
    }
    if (s.children) {
      const found = findSection(s.children, id)
      if (found) {
        return found
      }
    }
  }
  return null
}

const getFullPath = (section: Section, sections: Array<Section>): string => {
  let path = section.title
  let parentId = section.parentId
  while (parentId) {
    const parent = findSection(sections, parentId)
    if (parent) {
      path = `${parent.title} > ${path}`
      parentId = parent.parentId
    } else {
      parentId = undefined
    }
  }
  return path
}

function parseHtmlStringToListObject(htmlString: string): ListItem[] | null {
  // Parse the HTML string into a document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Retrieve the root ul element
  const rootUl = doc.querySelector('ul');
  if (!rootUl) {
    return null;
  }

  return parseUlToListObject(rootUl);
}

function parseUlToListObject(element: HTMLElement): ListItem[] {
  const items: ListItem[] = [];

  // @ts-ignore
  for (const li of element.children) {
    if (li.nodeName === 'LI') {
      const listItem: ListItem = {
        text: li.childNodes[0].nodeValue?.trim() || ""
      };

      // Check if there is a nested UL
      const nestedUl = li.querySelector('ul');
      if (nestedUl) {
        listItem.children = parseUlToListObject(nestedUl as HTMLElement);
      }

      items.push(listItem);
    }
  }

  return items;
}


export interface Section {
  id: string
  title: string
  isManuallyAdded: boolean
  parentId?: string
  children?: Array<Section>
  summary?: {
    brief: string
    detailed: string
  }
  caseStudies?: Array<string>
  references?: Array<string>
  examples?: Array<string>
}

interface IContextProps {
  actions: {
    setSubject: (subject: string) => void
    setApiKey: (apiKey: string) => void
    getBreakdown: (section: Section) => void
    getBriefSummary: (section: Section) => void
    getDetailedSummary: (section: Section) => void
    getCaseStudies: (section: Section) => void
    getReferences: (section: Section) => void
    getExamples: (section: Section) => void
    clear: () => void
  }
  subject: string
  apiKey: string
  sections: Array<Section>
}

interface Props {
  children?: React.ReactNode
}

const ItemsToSections = (items: Array<ListItem>, parentId?: string) => {
  const sections: Array<Section> = []
  items.map((item) => {
    const id = uuid().toString()
    const s: Section = {
      id,
      title: item.text,
      isManuallyAdded: false,
    }
    if (item.children) {
      s.children = ItemsToSections(item.children, id)
    }
    if (parentId) {
      s.parentId = parentId
    }
    sections.push(s)
  })
  return sections
}

const AppContextProvider = (props: Props) => {

  const [subject, setSubject] = useState('')
  const [sections, setSections] = useState([] as Array<Section>)
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState<Array<OpenAI.ChatCompletionMessage | OpenAI.ChatCompletionUserMessageParam>>([])
  const [isInitialized, setIsInitialized] = useState(false)
  const [apiKey, setApiKey] = useState<string>('')
  const [openAiClient, setOpenAiClient] = useState<OpenAI>(new OpenAI({apiKey: '', dangerouslyAllowBrowser: true}))

  useEffect(() => {
    if (!apiKey) {
      return
    }
    setOpenAiClient(new OpenAI({apiKey, dangerouslyAllowBrowser: true}))
  }, [apiKey])
  useEffect(() => {
    const m = localStorage.getItem('messages')
    if (m) {
      setMessages(JSON.parse(m))
    }
    const s = localStorage.getItem('sections')
    if (s) {
      setSections(JSON.parse(s))
    }
    const sub = localStorage.getItem('subject')
    if (sub) {
      setSubject(sub)
    }
    const key = localStorage.getItem('apiKey')
    if (key) {
      setApiKey(key)
    }
    setIsInitialized(true)
  }, [])

  useEffect(() => {
    if(!isInitialized) {
      return
    }
    localStorage.setItem('messages', JSON.stringify(messages))
    localStorage.setItem('sections', JSON.stringify(sections))
    localStorage.setItem('subject', subject)
    localStorage.setItem('apiKey', apiKey)
  }, [messages, sections, subject, apiKey])

  const actions = {
    setSubject: (subject: string) => {
      setIsLoading(true)
      const p = GetInitialPrompt(subject)
      console.log('prompt: ', p)
      const m = CreateUserMessage(p)
      console.log('message: ', m)
      const allMessages = [...messages, m]
      console.log('allMessages: ', allMessages)
      const s: Array<Section> = []
      MakeChatGPTApiRequest(openAiClient, allMessages)
        .then((response) => {
          const o = parseHtmlStringToListObject(response.choices[0].message.content || '')
          console.log('o: ', o)
          if (o) {
            const s = ItemsToSections(o)
            console.log('s: ', s)
            setSections(s)
          }
          setSubject(subject)
          allMessages.push(response.choices[0].message)
          setMessages(allMessages)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    setApiKey: (apiKey: string) => {
      setApiKey(apiKey)
    },
    getBreakdown: (section: Section) => {
      setIsLoading(true)
      console.log('getBreakdown', section)
      const p = GetBreakdownPrompt(getFullPath(section, sections))
      const m = CreateUserMessage(p)
      const allMessages = [...messages, m]
      MakeChatGPTApiRequest(openAiClient, allMessages)
        .then((response) => {
          const o = parseHtmlStringToListObject(response.choices[0].message.content || '')
          if (o) {
            const foundSection = findSection(sections, section.id)
            console.log('sections before', sections)
            console.log('foundSection', foundSection)
            if (foundSection) {
              foundSection.children = ItemsToSections(o, section.id)
              setSections([...sections])
            }
            console.log('sections after', sections)
            allMessages.push(response.choices[0].message)
            setMessages(allMessages)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    getBriefSummary: (section: Section) => {
      setIsLoading(true)
      console.log('getBriefSummary', section)
      const p = GetBriefSummaryPrompt(getFullPath(section, sections))
      const m = CreateUserMessage(p)
      const allMessages = [...messages, m]
      MakeChatGPTApiRequest(openAiClient, allMessages)
        .then((response) => {
          const foundSection = findSection(sections, section.id)
          if (foundSection) {
            foundSection.summary = {
              brief: response.choices[0].message.content || '',
              detailed: ''
            }
            setSections([...sections])
            allMessages.push(response.choices[0].message)
            setMessages(allMessages)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    getDetailedSummary: (section: Section) => {
      setIsLoading(true)
      console.log('getDetailedSummary', section)
      const p = GetDetailedSummaryPrompt(getFullPath(section, sections))
      const m = CreateUserMessage(p)
      const allMessages = [...messages, m]
      MakeChatGPTApiRequest(openAiClient, allMessages)
        .then((response) => {
          const foundSection = findSection(sections, section.id)
          if (foundSection) {
            foundSection.summary = {
              brief: foundSection.summary?.brief || '',
              detailed: response.choices[0].message.content || ''
            }
            setSections([...sections])
            allMessages.push(response.choices[0].message)
            setMessages(allMessages)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    getCaseStudies: (section: Section) => {
      console.log('getCaseStudies', section)
    },
    getReferences: (section: Section) => {
      console.log('getReferences', section)
    },
    getExamples: (section: Section) => {
      console.log('getExamples', section)
    },
    clear: () => {
      setSubject('')
      setSections([])
      setMessages([])
      setApiKey('')
      localStorage.clear()
    }
  }

  return (
    <AppContext.Provider
      value={{
        actions,
        apiKey,
        subject,
        sections,
      }}>
      { isLoading && <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999999,
        width: '100vw',
        height: '100vh',
      }}><LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} /></div>}
      {props.children}
    </AppContext.Provider>
  )
}

export const useApp = (): IContextProps => useContext(AppContext)

export default AppContextProvider
