import React, {useState} from 'react'

import Item from './component/Item'


import './App.css'
import '@mantine/core/styles.css'

import AppContext, {Section, useApp} from './context/App'
import {
  Accordion,
  AppShell,
  Button,
  Collapse,
  Container,
  Flex,
  Input,
  MantineProvider,
  Stack,
  Text
} from '@mantine/core'

const convertSectionsToTree = (section: Section): any => {
  return {
    id: section.id,
    name: section.title,
    children: section.children && section.children.map((child) => convertSectionsToTree(child))
  }
}

const getAllIds = (sections: Array<Section>): Array<string> => {
  let ids: Array<string> = []
  sections.map((section) => {
    ids.push(section.id)
    if (section.children) {
      ids.push(...getAllIds(section.children))
    }
  })
  return ids
}

const getSectionById = (sections: Array<Section>, id: string): Section | null => {
  let s: Section | null = null
  sections.map((section) => {
    if (section.id === id) {
      s = section
    }
    if (s) {
      return
    }
    if (section.children) {
      s = getSectionById(section.children, id)
    }
  })
  return s
}

function App() {
  const {sections, actions, subject, apiKey} = useApp()

  const [topic, setTopic] = useState(subject)

  return (
    <AppShell header={{ height: 80 }}
              padding='md'
    >
      <AppShell.Header>
        <Flex direction='row' gap={10} style={{padding: 20}}>
          <Flex>
            <Text size='xl'>{subject}</Text>
          </Flex>
          <Input type={'text'} value={topic} placeholder={'Enter a subject'} onChange={(v) => {
            setTopic(v.target.value)
          }}/>
          <Button onClick={() => {
            actions.setSubject(topic)
          }}>Submit
          </Button>
          <Button variant='filled' color='orange' onClick={() => {
            actions.clear()
          }}>Clear
          </Button>
          <Input type={'text'} value={apiKey} placeholder={'Enter an OpenAI Api Key'} onChange={(v) => {
            actions.setApiKey(v.target.value)
          }}/>
        </Flex>
      </AppShell.Header>
      <AppShell.Main>
          <Flex>
            <Stack align='stretch'>
              { sections.length > 0 &&
                sections.map((section) => {
                  return (
                    <Item section={section}/>
                  )})
              }
            </Stack>
          </Flex>
      {/*// <TreeView*/}
      {/*//     data={flattenTree({ name: topic, id: 'root', children: sections.map(s => convertSectionsToTree(s))})}*/}
      {/*//     nodeRenderer={(p) => {*/}
      {/*//       const s = getSectionById(sections, p.element.id.toString())*/}
      {/*//       return (<div {...p.getNodeProps()}>*/}
      {/*//         { s && <SectionElem section={s}/> }*/}
      {/*//       </div>)*/}
      {/*//     }}*/}
      {/*//     // defaultExpandedIds={getAllIds(sections)}*/}
      {/*// />*/}
      </AppShell.Main>
    </AppShell>
  )
}

export default () => {
  return (
    <MantineProvider>
      <AppContext>
          <App />
      </AppContext>
    </MantineProvider>
  )
}
