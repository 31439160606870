import axios from 'axios'
import OpenAI from 'openai'

export interface Message {
  role: 'system' | 'user' | 'assistant'
  content: string
}

export function CreateUserMessage(content: string): OpenAI.ChatCompletionUserMessageParam {
  return {
    role: 'user',
    content
  }
}

export function CreateSystemMessage(content: string): OpenAI.ChatCompletionSystemMessageParam {
  return {
    role: 'system',
    content
  }
}

export const MakeChatGPTApiRequest = async (client: OpenAI, messages: Array<OpenAI.ChatCompletionMessage | OpenAI.ChatCompletionUserMessageParam>) => {

  const data = {
    model: 'gpt-4-turbo',
    messages: messages.slice(-15),
  }

  const r = await client.chat.completions.create(data)
  console.log('response: ', r)
  // const response = await axios.post('https://api.openai.com/v1/chat/completions', data, {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer sk-proj-JtkEd5mUMLggv08CuNPmT3BlbkFJvlbKopAPFciBTrxKA2pk`,
  //   },
  // })

  return r
}

export const GetInitialPrompt = (subject: string) => {
  return `Provide a detailed information structure breakdown of "${subject}". Include as many items/sections/areas/subtopics as possible. Cover a vast variety of different areas related to the subject. They should be diverse and cover all potential directions that this subject could be expanded into. Output only the highest level list, no other text. Group and nest the items as needed. Do not limit the output to 10 or 15 bullet points but continue as long as it makes sense. Output in the html ul format that could be parsed.`
}

export const GetBreakdownPrompt = (subject: string) => {
  return `Break down "${subject}" into as many items/sections/areas/subtopics as possible. Not not include the path to the topic that is being broken down in the list. Output in the html ul format that could be parsed.`
}

export const GetBriefSummaryPrompt = (subject: string) => {
  return `Provide a brief summary of "${subject}". The summary should be concise and cover the most important aspects of the subject. It should be a high-level overview that gives a good understanding of what the subject is about.`
}

export const GetDetailedSummaryPrompt = (subject: string) => {
  return `Provide a detailed summary of "${subject}". The summary should be detailed and cover all the important aspects of the subject. It should be a comprehensive overview that gives a deep understanding of what the subject is about.`
}