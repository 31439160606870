import React from 'react'
import Markdown from 'react-markdown'
import {Section, useApp} from '../context/App'
import {Button, Center, Collapse, Flex} from '@mantine/core'

type SectionProps = {
  section: Section
}

const Item = ({ section }: SectionProps) => {
  const { actions } = useApp()
  const [expanded, setExpanded] = React.useState<boolean>(false)

  return <Flex direction='column' gap={10} style={{borderLeft: '1px solid blue'}}>
    <Flex direction='row'>
    <Button
      fullWidth
      justify='flex-start'
      variant='subtle'
      onClick={() => setExpanded(!expanded)}
      color={expanded ? 'blue' : 'gray'}
      size='compact-md'
    >
      <Center>
        <Button size='compact-xs' variant='subtle' onClick={() => {actions.getBreakdown(section)}}>⤵️</Button>
        <Button size='compact-xs' variant='subtle' onClick={() => {actions.getBriefSummary(section)}}>🔡</Button>
        <Button size='compact-xs' variant='subtle' onClick={() => {actions.getDetailedSummary(section)}}>🔠</Button>
      </Center>
      {section.title} ({section.children?.length || 0})
    </Button>
    <Flex
      direction='row'
    >
    </Flex>
    </Flex>
    <Collapse in={expanded}>

      <Flex
        direction='column'
        gap={10}
        style={{marginLeft: 15, paddingLeft: 15}}
      >
        { (section.summary && section.summary.brief) &&
            <Flex direction='column'>
                <Flex style={{fontWeight: 'bold'}}>Brief Summary</Flex>
                <Flex direction='column'>
                    <Markdown>{section.summary.brief}</Markdown>
                </Flex>
            </Flex>
        }
        { (section.summary && section.summary.detailed) &&
            <Flex direction='column'>
                <Flex style={{fontWeight: 'bold'}}>Detailed Summary</Flex>
                <Flex direction='column'>
                    <Markdown>{section.summary.detailed}</Markdown>
                </Flex>
            </Flex>
        }
        {section.children?.map((child) => <Item key={child.id} section={child}/>)}
      </Flex>
    </Collapse>
  </Flex>
}

export default Item